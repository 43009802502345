<template>
  <div class="tourn-card row row-cols-1 row-cols-md-2 row-cols-xxl-4 g-4 gy-5">
    <template v-for="(item, index) in tournamentlist">
      <div class="col" :key="index">
        <router-link
          :to="{ name: 'Condition', params: { id: item.tournamentno } }"
        >
          <div class="card h-100">
            <div class="card-img-wrapper">
              <img
                :src="
                  item.tourbinary &&
                  item.tourbinary[0] &&
                  item.tourbinary[0].filepath
                    | get_img(
                      'https://krstorage.s3.ap-northeast-2.amazonaws.com/files/tournament/06edda88fe1bb993db760ee25b8ad9fa.jpg'
                    )
                "
              />
            </div>
            <div class="card-body">
              <span class="tourn-type badge rounded-pill"
                >{{ tournament_type(item.tourtype_fk) }} /
                {{ item.tour_rank_type | tour_rank_type }}
              </span>

              <h5 class="card-title">{{ item.title }}</h5>
            </div>
            <div class="card-footer">
              <p
                class="text-date text-truncate mb-1"
                v-if="
                  item.timezone_startdate &&
                  item.timezone_startdate.timezone_private
                "
              >
                <dfn
                  class="time-zone-mark badge rounded-pill bg-orange"
                  title="Connect time"
                  >CT</dfn
                >
                {{ item.timezone_startdate.timezone_private | dateformat }}
                ~
                {{ item.timezone_enddate.timezone_private | dateformat }}
              </p>

              <!-- 서비스 시간 -->
              <p
                class="text-date text-truncate"
                v-else-if="
                  item.timezone_startdate &&
                  item.timezone_startdate.timezone_common
                "
              >
                <dfn
                  class="time-zone-mark badge rounded-pill bg-green"
                  title="Standard time"
                  >ST</dfn
                >
                {{ item.timezone_startdate.timezone_common | dateformat }}
                ~
                {{ item.timezone_enddate.timezone_common | dateformat }}
              </p>
            </div>
          </div>
        </router-link>
      </div>
    </template>
  </div>
</template>
<script>
import { myMixin } from "@/mixin/myMixin";
export default {
  name: "Tournament",
  props: ["tournamentlist"],
  mixins: [myMixin],
};
</script>
